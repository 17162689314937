import { gsap, TextPlugin } from "gsap/all"
import { qs, qsa } from './dom'
gsap.registerPlugin(TextPlugin)

export const chatAnimation = () => {
	let chatPosition = 0,
		chatTimeline = gsap.timeline()
	const placeholderText = qs('.hero-chat--placeholder').innerText
	
	qsa('.hero-chat--item').forEach(function(item) {

		if (item.classList.contains('default')) {
			chatTimeline.to(".hero-chat--body-default .hero-chat--image", {opacity: 1, duration: 0.5})
			qsa('.hero-chat--message:not(.suggestion)', item).forEach(function(botItem) {
				chatTimeline.to(botItem, {y: 0, opacity: 1, duration: 0.5})
			})
			chatTimeline.add('suggestionsStart')
			qsa('.suggestion', item).forEach(function(botItem) {
				chatTimeline.to(botItem, {y: 0, opacity: 1, duration: 0.5, delay: 1}, 'startDots')
			})
		} else if (item.classList.contains('user')) {
			chatPosition = chatPosition + qs('.hero-chat--message-wrap', item).offsetHeight
			chatTimeline.set("#typing_text", {text: {value: ''}, ease: "none", delay: 1})
			chatTimeline.to("#typing_text", {text: {value: qs('.hero-chat--text', item).innerText}, duration: 3, ease: "none"})
			chatTimeline.set("#typing_text", {text: {value: placeholderText}, ease: "none"})
			chatTimeline.to('.hero-chat--body-default', {opacity: 0, duration: 0.1})
			chatTimeline.to(".hero-chat--body-wrap", {y: -chatPosition, duration: 1})
		} else if (item.classList.contains('loading')) {
			chatPosition = chatPosition + qs('.hero-chat--message-wrap', item).offsetHeight
			chatTimeline.to(".hero-chat--body-wrap", {y: -chatPosition, duration: 1})
			let dotsTimeline = gsap.timeline({ repeat: 1 }).add('startDots')
			.to('.dot.first', {keyframes: {opacity: [1, 0.66, 0.33] }, duration: 1}, 'startDots')
			.to('.dot.second', {keyframes: {opacity: [0.66, 1, 0.66] }, duration: 1}, 'startDots')
			.to('.dot.third', {keyframes: {opacity: [0.33, 0.66, 1] }, duration: 1}, 'startDots')
			chatTimeline.add(dotsTimeline)
			chatTimeline.set(item, {display: 'none'})
			chatPosition = chatPosition - qs('.hero-chat--message-wrap', item).offsetHeight
			chatTimeline.set(".hero-chat--body-wrap", {y: -chatPosition})
		} else {
			qsa('.hero-chat--bot-wrap', item).forEach(function(botItem, index) {
				chatPosition = chatPosition + botItem.offsetHeight
				if (index == 0) {
					chatTimeline.set(".hero-chat--body-wrap", {y: -chatPosition})
				} else {
					chatTimeline.to(".hero-chat--body-wrap", {y: -chatPosition, duration: 2, delay: 2})
				}
				
			})
		}
	})
	chatTimeline.set('.hero-chat--body', {'overflow-y': 'scroll'})
	chatTimeline.set('.hero-chat--body-wrap', {'position': 'relative', y: 0, 'top': '24px'})
	chatTimeline.add( function(){ qs('.hero-chat--body').scrollTop = qs('.hero-chat--body').scrollHeight } )
	//qs('.hero-chat--body').scrollTop = qs('.hero-chat--body').scrollHeight;
}