import { qs, qsa } from '../../resources/js/utils/dom'
import { setCookie } from '../../resources/js/utils/gate'
import { chatAnimation } from '../../resources/js/utils/tastegpt'
import { lightbox } from '../../resources/js/utils/lightbox'

window.addEventListener('message', event => {
   if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit' && qs('.with-form')) {
      const submittedformId = qs('.with-form').id
      setCookie(`${submittedformId}`, 'filled', 7)

      qsa('.gated').forEach((element) => {
        element.classList.remove('gated')
      })

      qsa('.btn-access').forEach((element) => {
        element.remove()
      })
   }
});

window.addEventListener('load', () => {
   if (qs('.hero-chat')) {
    chatAnimation()
   }

   if (qs('.hero-video')) {
    lightbox('.hero', true)
   }
});
