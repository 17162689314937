import { qs, qsa } from './dom'
import Swiper from "swiper";
import { Navigation } from 'swiper/modules';

export function lightbox(el, disableScroll) {
  qsa(el).forEach((element) => {
    element.addEventListener('click', (event) => {
      if (event.target.matches('[data-lightbox]')) {
        event.preventDefault();
        if (disableScroll) {
          qs('body').classList.add('disabled-scroll')
        }
        qs(event.target.dataset.lightbox).classList.add('openned')
      }
      if (event.target.matches('.lightbox--overlay')) {
        event.preventDefault();
        if (disableScroll) {
          qs('body').classList.remove('disabled-scroll')
        }
        qsa('.lightbox--container').forEach(function(item) {
          item.classList.remove('openned');
        });
      }
    });
  });
}

export function lightboxSlide(event) {
  if (event.target.matches('[data-active]')) {
    let element = qs(event.target.dataset.lightbox + ' .lightbox--slider');
    let lightboxSlider = new Swiper(element, {
      modules: [Navigation],
      slidesPerView: 1,
      loop: false,
      speed: 1000,
      navigation: {
        nextEl: '.swiper-button-next.inner',
        prevEl: '.swiper-button-prev.inner',
      },
    });

    //Scroll to clicked slides
    lightboxSlider.slideTo(event.target.dataset.active, 0);
    lightboxSlider.update();
  }
}
